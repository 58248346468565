import React, { useEffect, useState } from "react";
import HeaderItems from "./HeaderItems";
import { FiChevronDown } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../store/actions";
import { authService } from "../services/authService";
import { logout } from "../store/slices/authSlice";
import { Disclosure } from "@headlessui/react";

function MobileNavigation() {
  const copyCartObj = JSON.parse(localStorage.getItem("cart"));
  const animateFrom = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };

  let navigate = useNavigate();
  const [fetchedProducts, setFectchedProducts] = useState([]);
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.masterData);
  const token = authService.getCurrentUserVerificationCode();
  const corporateId = authService.getCustomerCorporateId();
  const customerId = authService.getCurrentCustomerId();

  useEffect(() => {
    if (token) {
      dispatch(logout());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (!products) {
      const data = {
        sql_ref_no: "46",
        corporateId: corporateId ? +corporateId : 1,
        customer_id: customerId?.toString(),
        option: "1",
      };

      dispatch(getProducts(data))
        .unwrap()
        .catch((err) => {
          // navigate("/login");

          return err;
        });
    }

    if (products) setFectchedProducts(products?.product_info);
  }, [dispatch, products, navigate]);

  // useEffect(() => {
  //   if (copyCartObj) setFectchedLocalCart(copyCartObj);
  // }, [copyCartObj]);

  const handleRedirect = (index, product) => {
    switch (index) {
      case "Food Baskets":
        navigate(`/home/plans/${product?.product_id}`);
        break;
      case "cart":
        navigate("/cart");
        break;
      case "wallet":
        navigate("/home/wallet");
        break;
      case "Pick Your Basket":
        navigate("/basket/create-basket");
        break;
      case "Create Your Own Basket":
        navigate("/basket/create-basket");
        break;
      default:
        return;
    }
  };
  return (
    <div className="absolute left-0 right-0 top-12 h-screen w-full bg-white px-4 py-2 text-sm font-semibold sm:top-16 lg:hidden">
      <div className="mx-auto flex w-auto max-w-2xl flex-col space-y-1 pb-16">
        <motion.div
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.1 }}
        >
          <div className="w-full">
            <div className="mx-auto w-full rounded-2xl bg-white">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        open ? "bg-[#F0F4E7] text-olivine-500" : "text-gray-600"
                      } flex w-full justify-between px-5 py-3 text-left text-base font-semibold hover:bg-[#F0F4E7] hover:text-olivine-500 focus:outline-none focus-visible:ring focus-visible:ring-olivine-500 focus-visible:ring-opacity-75`}
                    >
                      <span>Buy</span>
                      <FiChevronDown
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="mt-2 rounded-md bg-[#F4F5F1] py-2 text-sm text-gray-600">
                      {fetchedProducts &&
                        fetchedProducts?.map((product, index) => (
                          <div
                            onClick={() =>
                              handleRedirect(product?.name, product)
                            }
                            key={index}
                            className="cursor-pointer px-8 py-3 text-gray-500 hover:bg-gray-200 hover:text-gray-700 xsm:px-10 sm:px-14"
                          >
                            <span
                              key={index}
                              // href="#"
                              class="block text-sm font-semibold sm:text-base"
                            >
                              {product?.name}
                            </span>
                          </div>
                        ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.2 }}
        >
          <Link to="/gift">
            <HeaderItems title="Gift a Basket" />
          </Link>
        </motion.div>
        <motion.div
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.3 }}
        >
          <a href="https://corporate.grocedy.com" target="blank">
            <HeaderItems title="Corporate" />
          </a>
        </motion.div>
        <motion.div
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.4 }}
        >
          <Link to="/about">
            <HeaderItems title="About" />
          </Link>
        </motion.div>
        <motion.div
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.5 }}
        >
          <Link to="/contact">
            <HeaderItems title="Contact Us" />
          </Link>
        </motion.div>
        <motion.div
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.6 }}
        >
          <div
            title="Cart"
            className="group z-20 cursor-pointer py-3 pl-5 hover:bg-[#F0F4E7] hover:text-olivine-500"
          >
            <Link to="/cart">
              <div className="relative">
                <div className="text-base text-gray-600 group-hover:text-olivine-500">
                  My Cart
                </div>
                {copyCartObj && copyCartObj?.length > 0 ? (
                  <div className="absolute -top-[10px] left-[8px] flex h-4 w-5 items-center justify-center rounded-full bg-red-500">
                    <div className="flex text-[11px] font-semibold text-white">
                      {copyCartObj &&
                        copyCartObj?.length > 0 &&
                        copyCartObj?.length}
                    </div>
                  </div>
                ) : null}
              </div>
            </Link>
          </div>
        </motion.div>
        <motion.div
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.7 }}
        >
          <div className="mt-4 flex w-full justify-center rounded-md bg-olivine-500 py-3 text-sm transition duration-300 hover:bg-olivine-600 sm:w-auto">
            <Link to="/register">
              <button className=" font-semibold text-white">
                Create Account
              </button>
            </Link>
          </div>
        </motion.div>
        <motion.div
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.8 }}
        >
          <Link to="/login">
            <div className="mt-4 flex w-full cursor-pointer justify-center rounded-md border-[1px] border-olivine-500 py-3 text-sm font-semibold hover:bg-olivine-100">
              <button className="font-semibold text-olivine-500 hover:text-olivine-600">
                Login
              </button>
            </div>
          </Link>
        </motion.div>
      </div>
    </div>
  );
}

export default MobileNavigation;
