import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deliveryData,
  init_data,
  ninData,
  userProfileData,
} from "../../core/helpers";
import MasterDataService from "../../services/masterData";
import { startLoad, stopLoad } from "../slices/appSlice";
import { handleError } from "../slices/errorSlice";

export const addPhonenumber = createAsyncThunk(
  "add/phoneNumber",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.addPhoneNumber(data);

      dispatch(todoListStatus(init_data));

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const verifyPhonenumber = createAsyncThunk(
  "verify/phoneNumber",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.verifyPhoneNumber(data);
      localStorage.setItem("user_phone", res?.message_ref_no);
      dispatch(todoListStatus(init_data));
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const fetchCountries = createAsyncThunk(
  "verify/fetchCountries",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.fetchCountryInfo(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const addDelivery = createAsyncThunk(
  "verify/addDelivery",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.addDelivery(data);
      dispatch(getDelivery(deliveryData));
      dispatch(todoListStatus(init_data));
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getDelivery = createAsyncThunk(
  "get/getDelivery",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.getDelivery(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const addBvn = createAsyncThunk(
  "add/addBvn",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.addBvn(data);
      dispatch(todoListStatus(init_data));
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getBvn = createAsyncThunk(
  "get/getBvn",
  async (_, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.getBvn();
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const addNin = createAsyncThunk(
  "add/addNin",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.addNin(data);
      dispatch(getNin(ninData));
      dispatch(todoListStatus(init_data));
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getNin = createAsyncThunk(
  "get/getNin",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.getNin(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const documentUploadGet = createAsyncThunk(
  "get/getDocumentUploaded",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.documentUploadGet(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const todoListStatus = createAsyncThunk(
  "add/todoListStatus",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.todoListStatus(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "get/userProfile",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.getUserProfile(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "get/userInfo",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.getUserInfo(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getUserProfileSet = createAsyncThunk(
  "get/userProfileSet",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.getUserProfileSet(data);

      dispatch(getUserInfo(userProfileData));
      dispatch(todoListStatus(init_data));
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const createTransactionPin = createAsyncThunk(
  "get/createTransactionPin",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.setTransactionPin(data);
      dispatch(todoListStatus(init_data));
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const updateTransactionPin = createAsyncThunk(
  "set/updateTransactionPin",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.setUpdatePin(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const verifyTransactionPinCode = createAsyncThunk(
  "get/verifyTransactionPin",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.verifyTransactionPin(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getDocumentFileId = createAsyncThunk(
  "get/getDocumentFileId",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.getDocumentFileId(data);
      dispatch(todoListStatus(init_data));
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getDocumentUpload = createAsyncThunk(
  "get/getDocumentUpload",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.getDocumentUpload(data);
      dispatch(todoListStatus(init_data));
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getPlans = createAsyncThunk(
  "get/plans",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.getPlans(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getPlan = createAsyncThunk(
  "get/plan",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.getPlan(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getProducts = createAsyncThunk(
  "get/products",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.getProducts(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getProductPlans = createAsyncThunk(
  "get/productPlans",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.getProductPlans(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getCartProductPlan = createAsyncThunk(
  "get/cartProductPlans",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.getCartProductPlan(data);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getBillerInfos = createAsyncThunk(
  "get/billersInfo",
  async (_, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.getBillerInfo();

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const activePlans = createAsyncThunk(
  "get/activePlans",
  async (id, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.activeCustomerPlans(id);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getDeliveryData = createAsyncThunk(
  "get/deliveryData",
  async (id, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.deliveryData(id);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const cancelPlan = createAsyncThunk(
  "post/cancelPlans",
  async (id, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.cancelCustomerPlan(id);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const reactivatePlan = createAsyncThunk(
  "post/reactivatePlans",
  async (id, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.reactivateCustomerPlan(id);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const upgradePlan = createAsyncThunk(
  "post/upgradePlans",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.upgradeCustomerPlan(
        payload.id,
        payload.data
      );

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const downgradePlan = createAsyncThunk(
  "post/downgradePlans",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.downgradeCustomerPlan(
        payload.id,
        payload.data
      );

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const bvnVerification = createAsyncThunk(
  "post/bvnVerification",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.bvnVerify(payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const rateShopping = createAsyncThunk(
  "post/shopping_rating",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.shoppingRating(payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const rateShoppingSubscription = createAsyncThunk(
  "post/shopping_rating_subscription",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.shoppingRatingSubscription(payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const rateShipping = createAsyncThunk(
  "post/shipping_rating",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.shippingRating(payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const rateVasPaybillsShopping = createAsyncThunk(
  "post/vas_paybills_shopping_rating",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.shoppingVasPaybillsRating(payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const rateVasShopping = createAsyncThunk(
  "post/vas_shopping_rating",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.shoppingVasRating(payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const uploadCorporateId = createAsyncThunk(
  "post/upload_corporate_id",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.corporateIdUpload(payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const deleteCorporateId = createAsyncThunk(
  "delete/delete_corporate_id",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.corporateIdDelete(payload);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const fetchRecommendations = createAsyncThunk(
  "post/fetchRecommendations",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.handleRecommendationsFetch(
        payload?.customer_id,
        payload?.data
      );

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const hideAdsInfo = createAsyncThunk(
  "get/hideAdsInfo",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.handleAdsHide(
        payload?.customer_id,
        payload?.adRefNo
      );

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const showAdsInfo = createAsyncThunk(
  "get/showAdsInfo",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.handleAdsShow(
        payload?.customer_id,
        payload?.adRefNo
      );

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const showSettingsAdsInfo = createAsyncThunk(
  "get/showSettingsAdsInfo",
  async (customer_id, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.handleShowSettingsAds(customer_id);

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const toggleSettingsAdsInfo = createAsyncThunk(
  "get/toggleSettingsAdsInfo",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.handleToggleSettingsAds(
        payload?.customer_id,
        payload?.visibility_id
      );

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const fetchProductFeatureInfo = createAsyncThunk(
  "get/fetchProductFeatureInfo",
  async (_, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await MasterDataService.handleFeatureInfo();

      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
