import React, { useContext, useEffect, useState } from "react";
import Logo from "../assets/logo.svg";
import Ospan from "../assets/avatar.svg";
import { Link, useNavigate } from "react-router-dom";
import { FaShoppingBasket } from "react-icons/fa";
import { BiMenuAltRight, BiDotsVerticalRounded } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { IoLogOut } from "react-icons/io5";
import { IoMdBasket, IoMdListBox } from "react-icons/io";
import { HiHome } from "react-icons/hi";
import {
  IoMdWallet,
  IoMdNotifications,
  IoMdArrowDropdown,
} from "react-icons/io";
import { BsFillFileTextFill, BsPersonCircle } from "react-icons/bs";
import RxjsStoreContext from "../context/RxjsStoreContext";
import { IoMdCart } from "react-icons/io";
import { RiFolderHistoryFill, RiFolderHistoryLine } from "react-icons/ri";
import SignedInHeaderItems from "./SignedInHeaderItems";
import SignedInMobileNavigation from "./SignedInMobileNavigation";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/slices/authSlice";
import { Menu } from "@headlessui/react";

import {
  getPaymentNotification,
  getProducts,
  getUserInfo,
  getWalletBalance,
  productPurchaseGet,
} from "../store/actions";
import { resetUser } from "../store/slices/userSlice";
import { resetVas } from "../store/slices/vasSlice";
import { resetPayment } from "../store/slices/paymentSlice";
import { resetMasterdata } from "../store/slices/masterDataSlice";
import { resetCart } from "../store/slices/cartSlice";
import { authService } from "../services/authService";
import Navbar from "./Navbar";
import { resetModal } from "../store/slices/modalSlice";
import { resetVoucher } from "../store/slices/voucherSlice";
import Container from "./pages/my_container/Container";

function SignedInNavbarImproved() {
  let navigate = useNavigate();
  const rxjsStore = useContext(RxjsStoreContext);
  // const copyCartObj = JSON.parse(localStorage.getItem("cart"));
  const [customBasket, setCustomBasket] = useState({});
  const [fectcheUserProfile, setFectcheUserProfile] = useState({});
  const [fetchedProducts, setFectchedProducts] = useState([]);
  const [totalCartItems, setTotalCartItems] = useState(0);
  const [fetchedUserBalance, setFetchedUserBalance] = useState();
  const [fetchedCart, setFetchedCart] = useState({});
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const user_email = authService.getCurrentUserEmail();
  const userId = authService.getCurrentUserID();
  const verification_code = authService.getCurrentUserVerificationCode();
  const customerId = authService.getCurrentUserCustomerId();
  const corporateId = authService.getCustomerCorporateId();

  const { userInfo, products } = useSelector((state) => state.masterData);
  const { cart, paymentNotify } = useSelector((state) => state.cart);
  const { walletBalance } = useSelector((state) => state.user);
  const { unreadNotify } = useSelector((state) => state.modal);

  const MINUTE_MS = 60000;

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getPaymentNotification());
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [dispatch]);

  useEffect(() => {
    if (!paymentNotify) {
      dispatch(getPaymentNotification());
    }
  }, [paymentNotify, dispatch]);

  //  For custom basket
  useEffect(() => {
    let tempCustomBasket = {
      plan_name: "Custom Basket",
      notes: "",
      discount: "00.00%",
      customer_id: authService.getCurrentUserZohoCustomerId(),
      shipping_charge: 0,
      adjustment: 0,
      adjustment_description: "custom basket item",
      exchange_rate: 1,
      line_items: [],
    };
    rxjsStore.getUserSelectedBasket().subscribe((productsListMap) => {
      for (let [key, product] of productsListMap) {
        const tempItems = {
          item_id: product.item_id,
          name: product.name,
          rate: product.item_rate_zoho,
          quantity: product.qty,
          unit: product.unit,
          item_total: product.qty * Number(product.item_rate_zoho),
        };
        tempCustomBasket.line_items.push(tempItems);
      }
      setCustomBasket(tempCustomBasket);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!walletBalance) {
      const data = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };
      dispatch(getWalletBalance(data));
    }

    if (walletBalance) setFetchedUserBalance(walletBalance);
  }, [dispatch, userId, customerId, verification_code, walletBalance]);

  useEffect(() => {
    if (!userInfo) {
      const data = {
        sql_ref_no: "11",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        option: "7",
      };

      dispatch(getUserInfo(data));
    }

    if (userInfo) setFectcheUserProfile(userInfo);
  }, [dispatch, userInfo, customerId, userId, verification_code]);

  useEffect(() => {
    if (!products) {
      const data = {
        sql_ref_no: "46",
        corporateId: corporateId ? +corporateId : 1,
        customer_id: customerId?.toString(),
        option: "1",
      };

      dispatch(getProducts(data))
        .unwrap()
        .catch((err) => {
          // navigate("/login");

          return err;
        });
    }

    if (products) {
      // localStorage.setItem("selected_plan")

      const filterProductId = products?.product_info
        ?.filter((pp) => pp?.name === "Food Baskets")
        .pop();
      localStorage.setItem("selected_plan", filterProductId?.product_id);
      setFectchedProducts(products?.product_info);
    }
  }, [dispatch, products, navigate, corporateId]);

  useEffect(() => {
    if (!cart && user_email) {
      const data = {
        user_email,
      };

      dispatch(productPurchaseGet(data))
        .unwrap()
        .catch((err) => {
          // navigate("/login");

          return err;
        });
    }
  }, [dispatch, cart, user_email, navigate]);

  useEffect(() => {
    let total = 0;
    if (customBasket?.line_items?.length && !fetchedCart?.items?.length) {
      total = 1;
    }

    if (!customBasket?.line_items?.length && fetchedCart?.items?.length) {
      total = fetchedCart?.items?.length;
    }

    if (customBasket?.line_items?.length && fetchedCart?.items?.length) {
      total = fetchedCart?.items?.length + 1;
    }
    setTotalCartItems(total);
  }, [customBasket?.line_items?.length, fetchedCart?.items?.length]);

  useEffect(() => {
    if (cart) {
      setFetchedCart(cart);
    }
  }, [cart]);

  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetUser());
    dispatch(resetVas());
    dispatch(resetPayment());
    dispatch(resetMasterdata());
    dispatch(resetCart());
    dispatch(resetModal());
    dispatch(resetVoucher());
    authService.logOut();
    navigate("/login");
  };

  const handleRedirect = (index, product) => {
    switch (index) {
      case "Food Baskets":
        navigate(`/home/plans/${product?.product_id}`);
        break;

      case "GroChill":
        navigate("/butchers/create-basket");
        break;

      case "cart":
        navigate("/cart");
        break;
      case "wallet":
        navigate("/home/wallet");
        break;
      case "Pick Your Basket":
        navigate("/basket/create-basket");
        break;
      case "Create Your Own Basket":
        navigate("/basket/create-basket");
        break;
      default:
        return;
    }
  };

  return (
    <>
      {authService.isLogin() && (
        <>
          <Container className="sticky top-0 z-40 flex h-12 flex-row items-center justify-between bg-white text-xs shadow-sm @container sm:h-16">
            <div className="whitespace-nowrap transition duration-300 hover:opacity-80 lg:mr-16 xl:mr-24">
              <Link to="/">
                <img
                  src={Logo}
                  className="aspect-auto h-7 w-auto cursor-pointer object-cover sm:h-11"
                  alt="logo"
                />
              </Link>
            </div>

            <div className="hidden items-center space-x-4 lg:inline-flex xl:space-x-6">
              <div
                onClick={() => navigate("/home-welcome")}
                className="rounded-md px-3 py-2 text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-olivine-500 hover:ring-[1px] hover:ring-gray-200"
              >
                <SignedInHeaderItems
                  Icon={<HiHome className="h-5 w-5" />}
                  title="Home"
                />
              </div>
              <div
                onClick={() => navigate("/home")}
                className="rounded-md px-3 py-2 text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-olivine-500 hover:ring-[1px] hover:ring-gray-200"
              >
                <SignedInHeaderItems
                  Icon={<IoMdListBox className="h-5 w-5" />}
                  title="Your plans"
                />
              </div>
              <div className="relative inline-block text-left">
                <Menu>
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-base text-gray-500 hover:bg-[#F0F4E7] hover:text-olivine-500 hover:ring-[1px] hover:ring-gray-200 focus:outline-none">
                      <div className="flex items-center space-x-1.5">
                        <div>
                          <IoMdBasket className="h-5 w-5" />
                        </div>
                        <div>Buy</div>
                      </div>
                    </Menu.Button>
                  </div>
                  <Menu.Items className="absolute right-0 mt-1 flex w-52 origin-top-right flex-col divide-y divide-gray-200 rounded-lg border-[1px] border-gray-200 bg-white shadow-xl ring-opacity-5 focus:outline-none sm:w-64">
                    {fetchedProducts &&
                      fetchedProducts?.map((product, index) => (
                        <div key={index} className="p-1">
                          <div className="cursor-pointer rounded-md px-4 py-3.5 text-gray-500 hover:bg-[#F0F4E7] hover:text-olivine-500">
                            <span
                              onClick={() =>
                                handleRedirect(product?.name, product)
                              }
                              key={index}
                              // href="#"
                              className="block text-sm font-semibold sm:text-base"
                            >
                              {product?.name}
                            </span>
                          </div>
                        </div>
                      ))}
                  </Menu.Items>
                </Menu>
              </div>
              <div className="relative inline-block text-left">
                <Menu>
                  <Menu.Button className="rounded-md px-3 py-2 text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-olivine-500 hover:ring-[1px] hover:ring-gray-200">
                    <SignedInHeaderItems
                      Icon={<FaShoppingBasket className="h-5 w-5" />}
                      title="Gift"
                    />
                  </Menu.Button>
                  <Menu.Items className="absolute left-0 mt-1 flex w-64 origin-top-right flex-col divide-y divide-gray-200 rounded-lg border-[1px] border-gray-200 bg-white shadow-xl focus:outline-none">
                    <div className="p-1">
                      <Link to="/gift-a-plan">
                        <div className="block cursor-pointer rounded-md px-4 py-3.5 text-sm font-semibold text-gray-500 hover:bg-[#F0F4E7] hover:text-olivine-500">
                          Gift a Basket
                        </div>
                      </Link>
                    </div>
                    <div className="p-1">
                      <Link to="/voucher">
                        <div className="block cursor-pointer rounded-md px-4 py-3.5 text-sm font-semibold text-gray-500 hover:bg-[#F0F4E7] hover:text-olivine-500">
                          Gift a Voucher
                        </div>
                      </Link>
                    </div>
                  </Menu.Items>
                </Menu>
              </div>
              <div
                onClick={() => handleRedirect("wallet")}
                className="rounded-md px-3 py-2 text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-olivine-500 hover:ring-[1px] hover:ring-gray-200"
              >
                <SignedInHeaderItems
                  Icon={<IoMdWallet className="h-5 w-5" />}
                  title="Wallet"
                />
              </div>
            </div>
            <div className="hidden items-center sm:inline-flex">
              <div className="relative hidden pr-2 text-left sm:inline-block lg:hidden">
                <Menu>
                  <Menu.Button className="">
                    <div>
                      <div className="rounded-full p-1 text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-gray-600 hover:ring-[1px] hover:ring-gray-200">
                        <BiDotsVerticalRounded className="h-6 w-6 cursor-pointer text-gray-500" />
                      </div>
                    </div>
                  </Menu.Button>
                  <Menu.Items className="absolute right-2 flex w-80 origin-top-right flex-col divide-y-[1px] divide-gray-200 rounded-lg border-[1px] border-gray-200 bg-white shadow-xl focus:outline-none">
                    <div className="p-1">
                      <div
                        onClick={() => navigate("/home-welcome")}
                        className="flex items-center rounded-md px-3 py-4 pl-8 text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-olivine-500 lg:pl-0"
                      >
                        <SignedInHeaderItems
                          Icon={<HiHome className="h-5 w-5" />}
                          title="Home"
                        />
                      </div>
                    </div>
                    <div className="p-1">
                      <div
                        onClick={() => navigate("/home")}
                        className="flex items-center rounded-md px-3 py-4 pl-8 text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-olivine-500 lg:pl-0"
                      >
                        <SignedInHeaderItems
                          Icon={<IoMdListBox className="h-5 w-5" />}
                          title="Your plans"
                        />
                      </div>
                    </div>
                    <div className="relative inline-block text-left">
                      <Menu>
                        <div className="p-1">
                          <Menu.Button className="inline-flex w-full items-center rounded-md px-8 py-4 text-base text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-olivine-500 lg:pl-0">
                            <div className="flex items-center space-x-1.5">
                              <div>
                                <IoMdBasket className="h-5 w-5" />
                              </div>
                              <div>Buy</div>
                            </div>
                          </Menu.Button>
                        </div>
                        <Menu.Items className="absolute -right-4 z-50 mt-1 flex w-56 origin-top-right flex-col divide-y divide-gray-200 rounded-lg border-[1px] border-gray-200 bg-white shadow-xl ring-opacity-5 focus:outline-none">
                          {fetchedProducts &&
                            fetchedProducts?.map((product, index) => (
                              <div key={index} className="p-1">
                                <div className="cursor-pointer rounded px-4 py-3.5 text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-olivine-500">
                                  <span
                                    onClick={() =>
                                      handleRedirect(product?.name, product)
                                    }
                                    key={index}
                                    // href="#"
                                    className="block text-sm font-semibold sm:text-base"
                                  >
                                    {product?.name}
                                  </span>
                                </div>
                              </div>
                            ))}
                        </Menu.Items>
                      </Menu>
                    </div>
                    <div className="relative inline-block text-left">
                      <Menu>
                        <div className="flex w-full justify-center p-1">
                          <Menu.Button className="flex w-full items-center rounded-md px-3 py-4 pl-8 text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-olivine-500 lg:pl-0">
                            <SignedInHeaderItems
                              Icon={<FaShoppingBasket className="h-5 w-5" />}
                              title="Gift"
                            />
                          </Menu.Button>
                        </div>
                        <Menu.Items className="absolute -right-4 mt-1 flex w-64 origin-top-right flex-col divide-y divide-gray-200 rounded-lg border-[1px] border-gray-200 bg-white shadow-xl focus:outline-none">
                          <div className="p-1">
                            <Link to="/gift-a-plan">
                              <div className="block cursor-pointer rounded-md px-4 py-3.5 text-sm font-semibold text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-olivine-500">
                                Gift a Basket
                              </div>
                            </Link>
                          </div>
                          <div className="p-1">
                            <Link to="/voucher">
                              <div className="block cursor-pointer rounded-md px-4 py-3.5 text-sm font-semibold text-gray-500 hover:bg-[#F0F4E7] hover:text-olivine-500">
                                Gift a Voucher
                              </div>
                            </Link>
                          </div>
                        </Menu.Items>
                      </Menu>
                    </div>
                    <div className="p-1">
                      <div
                        onClick={() => handleRedirect("wallet")}
                        className="flex cursor-pointer items-center rounded-md px-3 py-4 pl-8 text-gray-500 transition duration-200 hover:rounded-b-lg hover:bg-[#F0F4E7] hover:text-olivine-500 lg:pl-0"
                      >
                        <SignedInHeaderItems
                          Icon={<IoMdWallet className="h-5 w-5" />}
                          title="Wallet"
                        />
                      </div>
                    </div>
                  </Menu.Items>
                </Menu>
              </div>
              <div className="flex items-center space-x-4">
                <div
                  title="Carts"
                  className="relative"
                  onClick={() => handleRedirect("cart")}
                >
                  <div className="cursor-pointer rounded-full p-1.5 transition duration-300 hover:bg-[#F0F4E7] hover:ring-[0.5px] hover:ring-gray-300">
                    <IoMdCart className="h-6 w-6 text-gray-700" />
                  </div>
                  {totalCartItems && totalCartItems > 0 ? (
                    <div className="absolute -right-[2px] -top-[2px] flex h-[18px] w-[18px] items-center justify-center rounded-full bg-red-500 text-center font-medium">
                      <p className="text-[10px] font-semibold text-white">
                        {totalCartItems && totalCartItems > 0 && totalCartItems}
                      </p>
                    </div>
                  ) : null}
                </div>

                <Link to="/home/notifications">
                  <div title="Notifications" className="relative">
                    <div className="rounded-full p-1.5 transition duration-300 hover:bg-[#F0F4E7] hover:ring-[0.5px] hover:ring-gray-300">
                      <IoMdNotifications className="h-6 w-6 text-gray-700" />
                    </div>
                    {unreadNotify && unreadNotify > 0 ? (
                      <div className="absolute -right-[2px] -top-[2px] flex h-[18px] w-[18px] items-center justify-center rounded-full bg-red-500 text-center font-medium">
                        <p className="text-[10px] font-semibold text-white">
                          {unreadNotify}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </Link>
                <div className="flex items-center space-x-1">
                  <div className="">
                    {fectcheUserProfile &&
                    fectcheUserProfile?.customer_info?.length &&
                    fectcheUserProfile?.customer_info[0]?.customer_image_url ? (
                      <img
                        src={
                          fectcheUserProfile?.customer_info[0]
                            ?.customer_image_url
                        }
                        className="aspect-auto h-9 w-9 rounded-full border-[1px] border-gray-400 object-cover"
                        alt=""
                      />
                    ) : (
                      <img
                        src={Ospan}
                        className="aspect-auto h-9 w-9 rounded-full border-[1px] border-gray-400 object-cover"
                        alt=""
                      />
                    )}
                  </div>
                  <div className="relative inline-block">
                    <Menu>
                      <div>
                        <Menu.Button className="text-gray-600 hover:text-olivine-500">
                          <div className="rounded-full hover:bg-[#F0F4E7] hover:ring-[1px] hover:ring-gray-200">
                            <IoMdArrowDropdown className="h-5 w-5 cursor-pointer" />
                          </div>
                        </Menu.Button>
                      </div>
                      <Menu.Items className="absolute right-0 flex w-72 origin-top-right flex-col divide-y-[1px] divide-gray-200 overflow-hidden rounded-lg border-[1px] border-gray-200 bg-white shadow-xl focus:outline-none">
                        <div className="rounded-t-lg px-4 py-5">
                          <span
                            //   href="#"
                            className="block text-sm font-semibold text-gray-500 hover:text-gray-600"
                          >
                            <div className="flex items-center space-x-2">
                              <div className="">
                                {fectcheUserProfile &&
                                fectcheUserProfile?.customer_info?.length &&
                                fectcheUserProfile?.customer_info[0]
                                  ?.customer_image_url ? (
                                  <img
                                    src={
                                      fectcheUserProfile?.customer_info[0]
                                        ?.customer_image_url
                                    }
                                    className="aspect-auto h-20 w-20 rounded-full border-[1px] border-gray-400 object-cover"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={Ospan}
                                    className="aspect-auto h-20 w-20 rounded-full border-[1px] border-gray-400 object-cover"
                                    alt=""
                                  />
                                )}
                              </div>
                              <div className="">
                                <p className="text-sm font-light text-gray-800">
                                  {fectcheUserProfile &&
                                  fectcheUserProfile?.customer_info?.length
                                    ? fectcheUserProfile?.customer_info[0]
                                        ?.customer_full_name
                                    : null}
                                </p>
                                <p className="text-xl font-semibold text-gravel-700">
                                  {fetchedUserBalance &&
                                  fetchedUserBalance?.available_balance
                                    ? `₦ ${Number(
                                        fetchedUserBalance?.available_balance
                                      ).toLocaleString()}`
                                    : "₦ 0.00"}
                                </p>
                              </div>
                            </div>
                          </span>
                        </div>
                        <div className="p-1">
                          <Link to="/home/profile">
                            <div className="flex cursor-pointer items-center rounded-md px-4 py-4 text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-olivine-500">
                              <SignedInHeaderItems
                                Icon={<BsPersonCircle className="h-5 w-5" />}
                                title="Profile"
                              />
                            </div>
                          </Link>
                        </div>
                        <div className="p-1">
                          <Link to="/home/transaction-history">
                            <div className="flex cursor-pointer items-center rounded-md px-4 py-4 text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-olivine-500">
                              <SignedInHeaderItems
                                Icon={
                                  <RiFolderHistoryFill className="h-5 w-5" />
                                }
                                title="Transaction History"
                              />
                            </div>
                          </Link>
                        </div>
                        <div className="p-1">
                          <Link to="/basket/order-history">
                            <div className="flex cursor-pointer items-center rounded-md px-4 py-4 text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-olivine-500">
                              <SignedInHeaderItems
                                Icon={
                                  <RiFolderHistoryLine className="h-5 w-5" />
                                }
                                title="Order History"
                              />
                            </div>
                          </Link>
                        </div>
                        <div className="p-1">
                          <div
                            onClick={handleLogout}
                            className="flex cursor-pointer items-center rounded-md rounded-b-md px-4 py-4 text-red-500 opacity-100 transition duration-200 hover:bg-[#F0F4E7] hover:opacity-70"
                          >
                            <SignedInHeaderItems
                              Icon={<IoLogOut className="h-5 w-5" />}
                              title="Log Out"
                            />
                          </div>
                        </div>
                      </Menu.Items>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>

            <div onClick={() => setOpen(!open)} className="sm:hidden">
              {open ? (
                <div className="rounded-full bg-[#E8F3D4] p-1 ring-[0.5px] ring-gray-300">
                  <CgClose className=" h-4 w-4 cursor-pointer text-gravel-400" />
                </div>
              ) : (
                <BiMenuAltRight className=" h-7 w-7 cursor-pointer text-gravel-400" />
              )}
            </div>
            {open && <SignedInMobileNavigation />}
          </Container>
        </>
      )}

      {!authService.isLogin() && <Navbar />}
    </>
  );
}

export default SignedInNavbarImproved;
