import "./App.css";
import About from "./components/About";
import ContactUs from "./components/pages/contact_us/ContactUs";
import EmailVerification from "./components/EmailVerification";
import ForgotPassword from "./components/ForgotPassword";
import GiftABasket from "./components/GiftABasket";
import Home from "./components/Home";
import LoginPage from "./components/LogInPage";
import NewPassword from "./components/NewPassword";
import PlansPage from "./components/PlansPage";
import Terms from "./components/Terms";
import ErrorPage from "./components/ErrorPage";
import TrackPage from "./components/pages/trackpage/[id]";
import TrackCreatePlanPage from "./components/pages/trackpage/createPlan/[id]";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignedInHome from "./components/SignedInHome";
import CreateNewPin from "./components/CreateNewPin";
import SignedInProductPlanPage from "./components/SignedInProductPlanPage";
import SignedInButcherPlanPage from "./components/SignedInButcherPlanPage";
import CartPage from "./components/CartPage";
import HomeTodoWelcome from "./components/HomeTodoWelcome";
import UploadValidIdCard from "./components/UploadValidIdCard";
import PrivateRoutes from "./core/utils/PrivateRoutes";
import Wallet from "./components/Wallet";
import SignedInPlansPage from "./components/SignedInPlansPage";
import PlansCartComplete from "./components/cart/[id]";
import PlansButcherComplete from "./components/butcher/[id]";

import SignedInProductPlanUsd from "./components/plan/[id]";
import TransactionReceipt from "./components/TransactionReceipt";
import GrocerySearch from "./components/pages/create_basket_page/GrocerySearch";
import ShowingResuilt from "./components/pages/create_basket_page/ShowingResuilt";
import SelectItem from "./components/pages/create_basket_page/SelectItem";

import BasketRoutes from "./routes/basketRoutes";
import GiftAPlan from "./components/pages/gift_a_basket/GiftAPlan";
import MyBasket from "./components/pages/gift_a_basket/MyBasket";

import Showall from "./components/pages/create_basket_page/Showall";
import ProfileSettingsMobile from "./components/pages/profileSettings/ProfileSettingsMobile";

import TransactionHistory from "./components/TransactionHistory";
import CreateAccount from "./components/pages/create_account/CreateAccount";
import OrderHistory from "./components/pages/create_basket_page/OrderHistory";
import RewardCentre from "./components/RewardCentre";
import Notifications from "./components/pages/notifications/Notifications";
import RewardBank from "./components/pages/reward_bank/RewardBank";
import RewardBankTwo from "./components/pages/reward_bank/RewardBankTwo";
import UpgradePlansPage from "./components/pages/upgradePlan/[id]";
import DowngradePlansPage from "./components/pages/upgradePlan/downgrade/[id]";
import ViewUpgradePlansPage from "./components/pages/upgradePlan/viewContentUpgradePage/[id]";
import ViewDowngradePlansPage from "./components/pages/upgradePlan/viewContentDowngradePage/[id]";
import RegisterPage from "./components/pages/create_account/RegisterPage";
import PrivateCooperateRoutes from "./core/utils/PrivateCooperateRoute";
import UserDataRecords from "./components/pages/termsAndConditions/UserDataRecords";
import PriceUpdate from "./components/pages/termsAndConditions/PriceUpdate";
import PrivacyPolice from "./components/pages/termsAndConditions/PrivacyPolicy";
import TermsAndConditions from "./components/pages/termsAndConditions/TermsAndConditions";
import ButcherRoutes from "./routes/butcherRoutes";
import StatisticsPage from "./components/pages/statistics/StatisticsPage";
import HomeTwo from "./components/HomeTwo";
import TransactionDetailDownloads from "./components/TransactionDetailsDownloads";
import PDFdocument from "./components/PDFdocument";
import HomeThree from "./components/HomeThree";
import Voucher from "./components/voucher/Voucher";
import VoucherHistory from "./components/voucher/buyAGiftVoucher/VoucherHistory";
import CreateVoucherSuccess from "./components/voucher/createAGiftVoucher/CreateVoucherSuccess";
import VoucherDetails from "./components/voucher/buyAGiftVoucher/VoucherDetails";
import VoucherDetailsMain from "./components/voucher/voucherDetail/VoucherDetailsMain";
import HomeTodo from "./components/HomeTodo";
import ListOfVouchers from "./components/voucher/bulkVoucher/ListOfVouchers";
import CreateVoucherBulkSuccess from "./components/voucher/createAGiftVoucher/CreateVoucherBulkSuccess";
import AssignAndShareBulkSuccess from "./components/voucher/createAGiftVoucher/AssignAndShareBulkSuccess";
import VoucherDetailsInfo from "./components/voucher/voucherDetail/VoucherDetailsInfo";
import RatingComment from "./components/Rating/RatingComment";
import OrderTransactionDetailsDeep from "./components/pages/create_basket_page/[id]";
import OrderTransactionDetailsDeepSubscription from "./components/pages/create_basket_page/subscription/[id]";
import VerifyEmail from "./components/VerifyEmail";
import RewardCenter from "./components/pages/reward_center/RewardCenter";
import RewardBankHistory from "./components/pages/reward_center/RewardBankHistory";
import RewardSummary from "./components/pages/reward_center/RewardSummary";
import TierPage from "./components/pages/reward_center/TierPage";
import ReferralBonus from "./components/pages/reward_center/ReferralBonus";
import RewardDetails from "./components/pages/reward_center/RewardDetails";
import SignedInHomeWelcome from "./components/pages/home_welcome/SignedInHomeWelcome";

function App() {
  // commentend some routes

  return (
    <Router>
      <Routes>
        <Route>
          <Route path="/home" element={<SignedInHome />} />
          <Route
            path="/home/todoWelcome"
            element={
              <PrivateRoutes>
                <HomeTodoWelcome />
              </PrivateRoutes>
            }
          />

          <Route
            path="/home/todo"
            element={
              <PrivateRoutes>
                <HomeTodo />
              </PrivateRoutes>
            }
          />

          <Route
            path="/home-welcome"
            element={
              <PrivateRoutes>
                <SignedInHomeWelcome />
              </PrivateRoutes>
            }
          />

          <Route
            path="/home/profile"
            element={<ProfileSettingsMobile />}
          ></Route>

          <Route
            path="/home/transaction-history"
            element={
              <PrivateCooperateRoutes>
                <PrivateRoutes>
                  <TransactionHistory />
                </PrivateRoutes>
              </PrivateCooperateRoutes>
            }
          />
          <Route path="/home/transactionPin" element={<CreateNewPin />} />

          <Route path="/home/validId" element={<UploadValidIdCard />} />
          <Route path="/home/plans" element={<SignedInPlansPage />} />
          <Route
            path="/home/wallet"
            element={
              <PrivateRoutes>
                <Wallet />
              </PrivateRoutes>
            }
          />
          <Route
            path="/home/transaction/detail"
            element={
              <PrivateRoutes>
                <TransactionDetailDownloads />
              </PrivateRoutes>
            }
          />

          <Route
            path="/home/transaction/pdf"
            element={
              <PrivateRoutes>
                <PDFdocument />
              </PrivateRoutes>
            }
          />

          <Route path="/home/wallet/reward-centre" element={<RewardBank />} />
          <Route path="/home/wallet/statistics" element={<StatisticsPage />} />
          <Route
            path="/home/transactionReciept"
            element={<TransactionReceipt />}
          />

          <Route
            path="/home/upgradePlan/:id"
            element={
              <PrivateCooperateRoutes>
                <PrivateRoutes>
                  <UpgradePlansPage />
                </PrivateRoutes>
              </PrivateCooperateRoutes>
            }
          />

          <Route
            path="/home/downgradePlan/:id"
            element={<DowngradePlansPage />}
          />

          <Route
            path="/home/viewUpgradePlan/:id"
            element={
              <PrivateCooperateRoutes>
                <PrivateRoutes>
                  <ViewUpgradePlansPage />
                </PrivateRoutes>
              </PrivateCooperateRoutes>
            }
          />

          <Route
            path="/home/viewDowngradePlan/:id"
            element={<ViewDowngradePlansPage />}
          />

          <Route
            path="/track/:id"
            element={
              <PrivateCooperateRoutes>
                <PrivateRoutes>
                  <TrackPage />
                </PrivateRoutes>
              </PrivateCooperateRoutes>
            }
          />

          <Route
            path="/track/create/:id"
            element={
              <PrivateCooperateRoutes>
                <PrivateRoutes>
                  <TrackCreatePlanPage />
                </PrivateRoutes>
              </PrivateCooperateRoutes>
            }
          />
        </Route>

        <Route
          path="/gift-a-plan"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <GiftAPlan />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />
        <Route path="/gift-a-plan/my-basket" element={<MyBasket />} />

        <Route
          path="/salesorder/:id"
          element={<OrderTransactionDetailsDeep />}
        />

        <Route
          path="/subscription/:id"
          element={<OrderTransactionDetailsDeepSubscription />}
        />

        <Route path="/basket/*" element={<BasketRoutes />} />
        <Route path="/butchers/*" element={<ButcherRoutes />} />
        <Route
          path="/voucher/*"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <Voucher />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />

        <Route
          path="/voucher/createVoucherSuccess"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <CreateVoucherSuccess />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />

        <Route
          path="/voucher/assignVoucherBulkSuccess"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <AssignAndShareBulkSuccess />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />

        <Route
          path="/voucher/createVoucherBulkSuccess"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <CreateVoucherBulkSuccess />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />

        {/* <Route path="/voucher/*" element={<Voucher />} /> */}
        <Route path="/voucher-history/*" element={<VoucherHistory />} />
        <Route path="/list-of-vouchers" element={<ListOfVouchers />} />

        <Route path="/user-data-records" element={<UserDataRecords />} />
        <Route path="/price-update" element={<PriceUpdate />} />
        <Route path="/privacy-policy" element={<PrivacyPolice />} />

        <Route path="/show" element={<ShowingResuilt />} />

        <Route path="/showAll" element={<Showall />} />

        <Route path="/select" element={<SelectItem />} />

        <Route path="/search" element={<GrocerySearch />} />
        <Route path="/" exact element={<Home />} />
        <Route path="/home/two" exact element={<HomeTwo />} />
        <Route path="/home/three" exact element={<HomeThree />} />
        <Route
          path="/voucher/voucher-details"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <VoucherDetails />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />

        <Route
          path="/voucher/voucher-details-main"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <VoucherDetailsMain />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />

        <Route
          path="/voucher/voucher-details-info"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <VoucherDetailsInfo />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />

        <Route path="/order" element={<OrderHistory />} />
        <Route path="/about" element={<About />} />
        <Route path="/rating-comments" element={<RatingComment />} />
        {/* <Route path="/plans" element={<PlansPage />} />  */}
        <Route path="/gift" element={<GiftABasket />} />
        <Route path="/contact" element={<ContactUs />} />
        {/* <Route path="/cart" element={<CreateYourBasket />} /> */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/password" element={<ForgotPassword />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        {/* <Route path="/login/home" element={<SignedInHome />} /> */}
        <Route path="/password/code" element={<EmailVerification />} />
        <Route path="/newPassword" element={<NewPassword />} />
        <Route path="/home/notifications" element={<Notifications />} />
        <Route path="/home/plans/:id" element={<SignedInProductPlanPage />} />
        <Route
          path="/reward-bank"
          element={
            <PrivateRoutes>
              <RewardCenter />
            </PrivateRoutes>
          }
        />
        <Route
          path="/reward-bank/history"
          element={
            <PrivateRoutes>
              <RewardBankHistory />
            </PrivateRoutes>
          }
        />
        <Route
          path="/reward-bank/summary"
          element={
            <PrivateRoutes>
              <RewardSummary />
            </PrivateRoutes>
          }
        />
        <Route
          path="/reward-bank/tier-page"
          element={
            <PrivateRoutes>
              <TierPage />
            </PrivateRoutes>
          }
        />
        <Route
          path="/reward-bank/referral-bonus"
          element={
            <PrivateRoutes>
              <ReferralBonus />
            </PrivateRoutes>
          }
        />
        <Route
          path="/reward-bank/history/:id"
          element={
            <PrivateRoutes>
              <RewardDetails />
            </PrivateRoutes>
          }
        />
        <Route
          path="/home/plans/butchers/:id"
          element={<SignedInButcherPlanPage />}
        />
        <Route
          path="/home/plan/:id"
          element={
            <PrivateCooperateRoutes>
              <SignedInProductPlanUsd />
            </PrivateCooperateRoutes>
          }
        />
        <Route
          path="/home/butcher/:id"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <PlansButcherComplete />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />
        <Route
          path="/home/cart/:id"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <PlansCartComplete />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />
        <Route
          path="/cart"
          element={
            <PrivateCooperateRoutes>
              <PrivateRoutes>
                <CartPage />
              </PrivateRoutes>
            </PrivateCooperateRoutes>
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
